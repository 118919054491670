<template>
  <div class="page page-templates">
    <div class="page-content">
      <div class="page-header">
        <div class="page-title">Шаблоны сообщений</div>
        <div class="page-header-button default-button" @click="createTemplate">
          <div class="icon icon-new"></div>
          <span>Новый шаблон</span>
        </div>
      </div>
      <div class="template-list" v-if="loading">
        <ul>
          <li v-for="i in 3" :key="i">
            <v-skeleton-loader type="list-item-avatar-two-line"/>
          </li>
        </ul>
      </div>
      <div class="template-list" v-else>
        <div class="empty-page" v-if="!templates.length">
          <img class="empty-page_icon" src="@/images/script-picture.svg" alt="script icon">
          <NoResults>
            <template v-slot:text>
              Добавьте заготовки сообщений – так будет проще сообщать кандидатам о собеседовании
            </template>
          </NoResults>
          <div class="default-button create-script-button" @click="createTemplate">
            <div class="icon icon-new"></div>
            <span>Новый шаблон</span>
          </div>
        </div>
        <ul v-else>
          <li v-for="template in templates" :key="template.id">
            <div
              class="template-list_item"
              @click="openTemplate(template.id)"
            >
              <div class="template-list_item_media">
                <div class="icon icon-envelope"></div>
              </div>
              <div class="template-list_item_inner">
                <div class="template-list_item_subtitle">{{ template.text }}</div>
              </div>
              <div class="template-list_item_after">
                <div class="icon icon-trash" @click.stop="remove(template.id)"></div>
                <div class="icon icon-pencil"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <NotifyMessageTemplate ref="notify_msg_template_modal"/>
  </div>
</template>

<script>
import NotifyMessageTemplate from '@/views/templates/NotifyMessageTemplate';
export default {
  name: 'Templates',
  components: { NotifyMessageTemplate },
  data() {
    return {
      loading: false,
      templates: [],
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.$server.request2('messageTemplate/get', {}, (data)=>{
        this.templates = data.response;
        this.loading = false;
      }, ()=>{
        this.loading = false;
      })
    },
    createTemplate() {
      this.$refs.notify_msg_template_modal.open({type: 'create'});
    },
    openTemplate(id) {
      this.$refs.notify_msg_template_modal.open({id, type: 'edit'});
    },
    remove(id) {

    }
  },
  mounted() {
    this.get();
  }
};
</script>

<style scoped lang="scss">
.template-list {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  &_add {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 6px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid var(--border-color);
  }

  li {
    border-bottom: 1px solid var(--border-color);
  }

  &_item {
    cursor: pointer;
    display: flex;

    &_media {
      flex-shrink: 0;
      margin-right: 20px;
      padding: 12px 0;

      .icon {
        width: 54px;
        height: 54px;
        font-size: 24px;
        background: var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }

    &_inner {
      width: 100%;
      min-width: 0;
      padding: 18px 0;
      display: flex;
      align-items: center;

      & > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
    }

    &_after {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > .icon {
        padding: 0 14px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(var(--page-color-main-rgb), .25);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .create-script-button {
    max-width: 300px;
    margin: 0 auto;
  }

  .no-results-block {
    margin-top: 50px;
  }
}
</style>